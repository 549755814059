<template>
    <t-dialog  width="680px" top="56px" :showInAttachedElement="true" draggable mode="modeless" :footer="false" v-model:visible="isOpenTips" :showOverlay="showOverlay" :preventScrollThrough="false">
        <template #header>
            <span style="display: flex; align-items: center;"><t-icon name="pin-filled" style="color: #f80000;"></t-icon> 小提示</span>
        </template>
        
        <template #body>
            <div class="knowledge-content-aside">
                <TonicSolfaKnowledge v-if="component == 'TonicSolfa'"></TonicSolfaKnowledge>
                <NotePitchNameKnowledge v-if="component == 'NotePitchName' "></NotePitchNameKnowledge>
                <NotePitchSingKnowledge v-if="component == 'NotePitchSing' || component == 'PitchRecognition'"></NotePitchSingKnowledge>
                <NotePitchTwelveEqualLawNameKnowledge v-if="component == 'NotePitchTwelveEqualLawName'"></NotePitchTwelveEqualLawNameKnowledge>
                <NotePitchTwelveEqualLawSingKnowledge v-if="component == 'NotePitchTwelveEqualLawSing'"></NotePitchTwelveEqualLawSingKnowledge>
                <NoteRhythmNameKnowledge v-if="component == 'NoteRhythmName' || component == 'NoteRhythmCount'"></NoteRhythmNameKnowledge>
                <TempoMarkKnowledge v-if="component == 'TempoMark'"></TempoMarkKnowledge>
                <ListeningRhythmExerciseKnowledge v-if="component == 'ListeningRhythmExercise'"></ListeningRhythmExerciseKnowledge>
                <NoteIntervalKnowledge v-if="component == 'NoteInterval'"></NoteIntervalKnowledge>
                <NoteInterval12LowKnowledge v-if="component == 'NoteInterval12Low'"></NoteInterval12LowKnowledge>
                <NoteIntervalSeniorKnowledge v-if="component == 'NoteIntervalSenior'"></NoteIntervalSeniorKnowledge>
                <ModeNameKnowledge v-if="component == 'ModeName' || component == 'SeniorModeNameRecognition'"></ModeNameKnowledge>
                <KeyBoard 
                v-if="
                component == 'ListeningPitchPrimary' ||
                component == 'ListeningIntervalPrimary' ||
                component == 'ListeningHarmonyPrimary' ||
                component == 'ListeningRhythmPitchPrimary' ||
                component == 'SeniorListeningPitch' ||
                component == 'SeniorListeningInterval' ||
                component == 'SeniorListeningHarmony' ||
                component == 'ListeningRhythmPitchSenior' ||
                component == 'piano'
                "
                ></KeyBoard>
            </div>
        </template>
    </t-dialog>
</template>

<script>
/* eslint-disable */
import NotePitchSingKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchSingKnowledge';
import NotePitchNameKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchNameKnowledge.vue';
import NotePitchTwelveEqualLawNameKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchTwelveEqualLawNameKnowledge.vue'
import NotePitchTwelveEqualLawSingKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchTwelveEqualLawSingKnowledge.vue'
import NoteRhythmNameKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteRhythmNameKnowledge.vue';
import TempoMarkKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/TempoMarkKnowledge.vue';
import ListeningRhythmExerciseKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/ListeningRhythmExerciseKnowledge.vue';     
import NoteIntervalKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteIntervalKnowledge.vue';
import NoteInterval12LowKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteInterval12LowKnowledge.vue';
import NoteIntervalSeniorKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteIntervalSeniorKnowledge.vue';
import KeyBoard from '@/components/MusicTheoryComponents/instruments/KeyBoard.vue';
import ModeNameKnowledge from './ModeNameKnowledge.vue';
import TonicSolfaKnowledge from './TonicSolfaKnowledge.vue';

export default {
    props: {
        info: {
            type: String,
            value: '',
        },
        isOpen: {
            type: Boolean,
            value: false
        },
        showOverlay: {
            type: Boolean,
            value: true
        },
    },
    watch: {
        info: {
            immediate: true,
            handler(n) {
                this.component = n
            }
        },
        isOpen: {
            immediate: true,
            handler(n) {
                this.isOpenTips = n
            }
        }
    },
    data() {
        return {
            isOpenTips: false
        }
    },
    components: {
        NotePitchSingKnowledge,
        NotePitchNameKnowledge,
        NotePitchTwelveEqualLawNameKnowledge,
        NotePitchTwelveEqualLawSingKnowledge,
        NoteRhythmNameKnowledge,
        TempoMarkKnowledge,
        ListeningRhythmExerciseKnowledge,
        NoteIntervalKnowledge,
        NoteInterval12LowKnowledge,
        NoteIntervalSeniorKnowledge,
        KeyBoard,
        ModeNameKnowledge,
        TonicSolfaKnowledge
    },
}
</script>
<style>
.t-dialog {
    box-sizing: border-box;
    margin-right: 30px !important;
    border: none;
}

.knowledge-content-aside {
    width: auto;
    display: flex;
    justify-content: center;
}

</style>