import AudioClear from "@/pages/technology/AudioClear/AudioClear.vue"
import VocalClear from "@/pages/technology/AudioClear/components/VocalClear.vue"
import VocalClearLogo from "@/pages/technology/AudioClear/components/VocalClearLogo.vue"
import Studio from '@/pages/studio/studio.vue'
import AIMusic from "@/pages/advertisement/components/AIMusic.vue"
// 网页导航
export const optionPages = [
    {
        path: '/audioClear',
        name: 'audioClear',
        content: 'AI人声处理',
        redirect: '/audioClear/voiceClearIndex',
        component: AudioClear,
        children: [
            {
                path: 'voiceClear',
                name: 'voiceClear',
                content: 'AI人声处理工作站',
                component: VocalClear
            },
            {
                path: 'voiceClearIndex',
                name: 'voiceClearIndex',
                content: 'AI人声处理',
                component:  VocalClearLogo
            }
        ]
    }
]
export const homePages = [
    {
        path: '/index/AIMusic',
        name: 'AIMusic',
        content: 'AI音乐',
        component: AIMusic
    },
    {
        path: '/index/studio',
        name: 'studio',
        content: '音乐制作',
        component: Studio
    }
]
