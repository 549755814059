<template>
  <div class="music-card" :style="{display: isLoaded ? '' : 'none'}" style="cursor: pointer;"  @click="palyAudio(music)">
    <t-image  :src="'https://www.duoyinchina.com/' + music.img_src" style="width: 100%; " @load="isLoaded=true"
      fit="fill"></t-image>

    <div class="music-info">
      <p style="text-align: center; font-weight: 500;"> {{ music.name }}</p>
      <p style="text-align: center; font-size: small;"> 曲风：{{ music.style }}</p>
    </div>
  </div>
</template>

<script>
import { audioPlayer } from '@/store/audioplayer';


export default {
  props: ['music'],
  data(){
    return {
      isPlaying: false,
      wavesurfer: null,
      isLoaded: false
    }
  },
  methods: {
    palyAudio(Info) {
      audioPlayer().isOpenAudioPlayer = false
      audioPlayer().isOpenAudioPlayer = true
      audioPlayer().audioInfo = Info;
    },
  }
}
</script>

<style lang="less" scoped>
@import url("/src/style/root.less");
.music-card {
  width: 160px;
  height: 220px;
  margin: 6px;
  overflow: hidden;
  border-radius: 20px;
  background-color: #000000cc;
  position: relative;

  .music-info {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #00000080;
    z-index: 10;
    color: #ffffff;
    text-align: center;
    padding: 6px;

    p {
      margin: 0px !important;
    }
  }
}


</style>