/* eslint-disable */
import { defineStore } from "pinia";
import { post } from "@/tools/tool";
import { generateOrderNumber } from "@/tools/tool"
import { get } from "@/tools/tool"
import { compareDateTime } from "@/tools/tool";

export const userstore = defineStore("userstore", {
  // 用户信息
  state: () => ({
    userinfo: null, // 用户信息
    isOpenRegister: false, // 是否打开扫码登录页面
    ordersList: [], // 用户订单信息
    isOpenOrderDialog: false, // 是否打开扫码支付页面
    orderinfo: {}, // 扫码支付对应的订单信息
    isMusicTheoryMember: false // 是否时候音基题库会员
  }),
  getters: {},
  actions: {
    async updateUserInfo() {
      // 从本地提取userinfo
      const userinfo = localStorage.getItem("duoyinuserinfo");
      if (userinfo) {
        this.userinfo = JSON.parse(userinfo);
        await this.updateUserOrders();
      } else {
        this.clearRegisterInfo();
      }
      return userinfo;
    },
    clearRegisterInfo() {
      this.userinfo = null;
      this.isOpenRegister = false
      this.ordersList = []
      this.isOpenOrderDialog = false
      this.orderinfo = {}
      this.isMusicTheoryMember = false
      localStorage.removeItem('duoyintoken')
      localStorage.removeItem('duoyinuserinfo')
    },
    async updateUserOrders() {  // 返回用户订单信息

      const token = localStorage.getItem("duoyintoken");
      const res = await post("/web/usercenter/orderlist", { token: token });

      if (res.code == 200 && res.data.length > 0) {
        this.ordersList = res.data;

        const order = this.ordersList.find(item => {  // 查验是否已经购买了相关订单，并在有效期限内
          if (item.status == 'SUCCESS' && item.user_id == this.userinfo.user_id && item.pro_id == '1717465119345345' && compareDateTime(item.pay_time)) {
            return item
          }
        })
        this.isMusicTheoryMember = order ? true : false
      } else {
        this.ordersList = [];
      }
    },
    async purchaseMusicTheory(pro_id) {
      const pro_info = await get(
        "/web/products/terminal/pc_pro/" + pro_id
      );
      const pro = pro_info[0];
      const token = localStorage.getItem("duoyintoken");

      this.orderinfo = {
        user_id: this.userinfo.user_id,
        pro_id: pro.pro_id,
        primaryImage: pro.primaryImage,
        order_id: generateOrderNumber(pro.id),
        price: pro.price,
        title: pro.title,
        token: token,
      };

      this.isOpenOrderDialog = true // 打开订单支付界面


    },
  },
});
