<template>
  <swiper 
    :modules="modules" 
    :slides-per-view="count"  
    :space-between="6" 
    loop
    :autoplay="{
      delay: t, // 自动播放间隔时间（毫秒）
      disableOnInteraction: false, // 用户交互后是否停止自动播放
      waitForTransition: false, // 不等待过渡效果完成就切换到下一张幻灯片
      setWrapperSize: true,
      cssMode: true, // 启用CSS模式
    }" 
    @swiper="onSwiper" 
    @slideChange="onSlideChange">
    >
    <swiper-slide v-for="(item, index) in musicList" :key="index">
      <MusicCard :music="item"></MusicCard>
    </swiper-slide>

  </swiper>
</template>

<script>
/* eslint-disable */
import MusicCard from './MusicCard.vue';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay'

// Import Swiper styles
export default {
  props: {
    list: {
      type: Array,
      default: []
    },
    t: {
      type: Number,
      default: 3000
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    MusicCard
  },
  watch: {
    list: function(n){
      if(n.length > 0){
        this.musicList = n
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.count =  (window.innerWidth / 160).toFixed()
    }
  },
  data() {
    return {
      musicList: [],
      count: 10
    }
  },
  setup() {
    const onSwiper = (swiper) => {
   
    };
    const onSlideChange = () => {
  
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>
