<template>
  <div class="studio-adver"
    style="background-image: url(https://www.duoyinchina.com/assits/images/studio-back.jpg); background-size: cover;">
    <div class="info-box">
        <div class="info">
          <div>
            <div class="title">芊蓦音乐工作室</div>
            <div class="sub-title">Polyphonic Music Studio</div>
            <div class="content-info">
              让每个音符讲述不同的故事。我们专注于原创流行音乐、游戏配乐、电影原声带的精心打造，以及专业的音效设计。无论是录音还是混音，我们都致力于将您的声音完美呈现。创意与专业，只为您的听觉盛宴！
            </div>
          </div>
        </div>

        <div class="info-bottom">
          <ButtonAdver title="我要作曲" name="arrow-right" :onclick="opendialog"></ButtonAdver>
        </div>
      </div>
  </div>
  <InfoDialog :isOpen="isOpen" @ternoff="isOpen=false"></InfoDialog>
</template>

<script>
/* eslint-disable */
import ButtonAdver from "@/components/button-adver.vue"
import InfoDialog from "./InfoDialog.vue";

export default {
  name: 'SwiperPage',
  components: {
    ButtonAdver,
    InfoDialog
  },
  data() {
    return {
      isLoaded: false,
      isOpen: false
    }
  },
  methods: {
    opendialog(){
      console.log('opendialog')
      this.isOpen = true
    }
  }
}
</script>

<style lang="less" scoped>
@import "/src//style/root.less";

@media (max-width: 900px) {
  .info {
    display: none;
  }
}


.studio-adver {
  width: 100vw;
  height: calc(100vw * 0.54);
  position: relative;

    .info-box {
      width: 600px;
      height: 300px;
      display: flex;
      flex-direction: column;
      position: absolute;
      left: calc(10%);
      bottom: calc(20%);

      .info {
        width: 500px;
        height: 300px;

        .title {
          font-size: 32px;
          font-weight: 500;
          color: #ffffffdd;
        }

        .sub-title {
          font-size: 17px;
          margin-top: 16px;
          color: #ffffffcc;
        }

        .content-info {
          margin-top: 16px;
          text-align: left;
          font-size: 17px;
          color: #ffffffcc;
        }
      }
    
  }


}
</style>