<template>
  <div class="map-card">
    <t-card :title="title" :bordered="false" hover-shadow>
      <template #actions>
        <t-dropdown :options="options" @click="changePosition" maxColumnWidth="400px">
          <t-button>更多地址..</t-button>
        </t-dropdown>
      </template>
      <div id="container"></div>
    </t-card>
  </div>


</template>


<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      title: "芊蓦音乐工作室地址",
      options: [
        { content: '芊蓦音乐（北京）通州土桥录音棚', value: [116.688322,39.874522] },
        { content: '芊蓦音乐（北京）工作室地址', value: [116.370942, 39.852851] },
        { content: '芊蓦音乐（天津）工作室-京滨工业园', value: [116.816553,39.548035] }
      ],
      position: [116.370942, 39.852851],
      map: null
    }
  },

  mounted() {
    this.gaodeMapInit(this.position)
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    changePosition(e) {
      this.title = e.content
      this.gaodeMapInit(e.value)
    },
    gaodeMapInit(position) {
      const that = this

      AMapLoader.load({
        key: "f8842733010e931745e345884d8c9ed0", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          that.map = new AMap.Map("container", {
            // pitch: 50, //地图俯仰角度，有效范围 0 度- 83 度
            // viewMode: '3D', //地图模式
            terrain: true,
            rotateEnable: true, //是否开启地图旋转交互 鼠标右键 + 鼠标画圈移动 或 键盘Ctrl + 鼠标左键画圈移动
            pitchEnable: true, //是否开启地图倾斜交互 鼠标右键 + 鼠标上下移动或键盘Ctrl + 鼠标左键上下移动
            zoom: 17, //初始化地图层级
            rotation: 0, //初始地图顺时针旋转的角度
            zooms: [2, 20], //地图显示的缩放级别范围
            center: position, //初始地图中心经纬度
          });

          const marker = new AMap.Marker({
            position: new AMap.LngLat(...position),
            offset: new AMap.Pixel(-10, -10),
            icon: "//www.duoyinchina.com/assits/images/地图定位icon.png", //添加 icon 图标 URL
            title: "北京",
          });
          that.map.add(marker);
        })
        .catch((e) => {
          console.log(e);
        });
    },

  }
}

</script>



<style scoped>
#container {
  width: 100%;
  height: 400px;
}

.map-card {
  width: 100%;
  box-sizing: border-box;
  padding: 32px;
}
</style>
