<template>
  <div>
      <DialogUsed :isOpen="true"></DialogUsed>
  </div>
</template>

<script>
/* eslint-disable */
import DialogUsed from '@/components/DialogShop.vue';
import WebLaw from '@/components/WebLaw.vue';

export default {
  components: {
    DialogUsed,
    WebLaw
  }
}



</script>