import {
  PrimaryQuestionIntros, singBox, makeNote, makeRandomNotes, InitVariate, getRandomFour,
  NotePrinterSenior, readText, judgmente, rateChange, finished, generate_unique_rhythms,
  makeNotesBox, makeNotePlay, makeNotes, intervalNameBox, questionInfoInit, get8notesfromArray,
  makeWavesAudio, TempoMarks, NoteTempoPrinter, randomTempoNotesBox, playTempo, makeHarmonyPlay, doesNotContainAny
} from "./_assits.js"

import { baseUrl } from "@/tools/tool"

export const logo_src = "https://www.duoyinchina.com/assits/images/芊蓦音乐logo原色空.png"

export const TestAssets = {

  baseUrl,

  notesBox: makeNotesBox(),

  singBox: singBox, // 数组； 唱名数组对象

  intervalNameBox, // 音程盒子

  TempoMarks, // 速度术语

  randomTempoNotesBox,

  PrimaryQuestionIntros, //方法； 初级乐理练习信息，参数1：路由页面的name；返回：问题页面信息描述数组对象

  makeNote, //方法； 根据输入的参数音符数组，参数1:音符数组 ['F/1', 'G/1', 'A/1', 'B/1', 'C/1']；返回：一个随机音符；

  makeNotes, //方法；参数1: array 音符盒子；参数2: Number返回4组，每组几个音符

  makeRandomNotes, //方法； 制作选项卡需要的音符，一般是一个要求的音符，和三个不一样的随机音符; 参数1: 答案音符，参数2:包括答案音符数组

  NoteTempoPrinter,

  NotePrinterSenior, // 将一个音符或是一个数组的音符打印到Html组件，参数1:div的id，参数2: 格式要求：音符数组[{count:1, duration: "16", note: ['F4']}]

  readText, // 文字阅读器, 参数1：string，要阅读的文字; 参数2: 阅读结束后要执行的函数

  judgmente, // 判卷

  rateChange, // 更改级别

  finished, // 当全部答题完毕

  makeHarmonyPlay,

  makeNotePlay, // 根据音符名称播放音符，可连续播放多个音符；参数1: Array，音符名称组成的数组；参数2:回调函数，返回是否正在播放isPlaying

  makeWavesAudio,  // 将音频的波形打印到div上，并进行控制播放；参数1:div的id；参数2: 音频地址url

  playTempo,

  get8notesfromArray, // 在音符数组里截取连续的8个音符；参数1: 音符数组，随机返回8个连续的音符数组

  generate_unique_rhythms, // 生成4组随机并不重复的节奏乐谱

  questionInfoInit, // 定义题库的基础信息参数

  InitVariate, // 问题init的时候自动定义初始变量

  getRandomFour, // 参数1:一个固定要求的元素。返回：在数组中随机抽取3个元素和固定要求的元素，不重复

  doesNotContainAny // 字符传的模糊查询，参数1是要查询的字符传，参数2是要对比的字符串数据数组，返回true/false
}