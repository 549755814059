<template>
  <t-dialog v-model:visible="visible" @close="ternOff" :cancelBtn="null" :confirmBtn="null" width="350px">
    <template #header>
      <span style="background-color: #ffffff; display: flex; align-items: center; justify-content: center;">
        <t-image src="https://www.duoyinchina.com/assits/images/芊蓦原色白底.png" :style="{ width: '32px', height: '32px' }"
          style="margin-right: 8px;"></t-image>
        <p>24小时客服</p>
      </span>
    </template>
    <div style="width: 100%; display: flex; align-items: center; justify-content: center;">
      <t-image src="https://www.duoyinchina.com/assits/images/wxQrCode.jpg"
        :style="{ width: '240px', height: '240px' }" />
    </div>

  </t-dialog>
</template>

<script>
export default {
  props: ['isOpen'],
  watch: {
    isOpen: {
      immediate: true,
      handler(n) {
        this.visible = n
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    ternOff() {
      this.$emit('ternoff')
    }
  }
}
</script>