<template>
  <t-dialog width="1000px" v-model:visible="visible" @close="ternOff" :cancelBtn="null" :confirmBtn="null">
      <template #header>
        <UserAvatar @token="getToken"></UserAvatar>
      </template>
     <AIMusic></AIMusic>
  </t-dialog>
</template>

<script>
/* eslint-disable */
import AIMusic from '@/pages/advertisement/components/AIMusic.vue';
import UserAvatar from '@/components/UserCenter/UserAvatar.vue';
import { MessagePlugin } from 'tdesign-vue-next';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AIMusic,
    UserAvatar
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(n) {
        this.visible = n
      }
    }
  },
  data() {
    return {
      visible: false,
      isLogin: false
    }
  },
  methods: {
    ternOff() {
      this.$emit('ternoff')
    },
    getToken(e){
      if(!e){
        MessagePlugin.error('您还未登录，请登录后再填写...')
      }
      console.log(e, '打开了')
    }
  }
}
</script>