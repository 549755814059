/* eslint-disable */
import { createRouter, createWebHashHistory } from "vue-router";
import { homePages, optionPages } from "./pages.js";
import {
  music_theory_pages,
  MusicTheoryExaminationPages,
} from "./musicTheoryRouterPages.js";
import Index from "@/pages/index/index.vue";
import ProductOrder from "@/pages/shop/orders/ProductOrder.vue";
import { beforEach } from "./beforeEach.js";
import Register from "@/pages/register/register.vue";

// 为组件分配地址
const routes = [
  {
    path: "/",
    name: "/",
    content: "首页",
    redirect: '/index/studio',
  },
  {
    path: "/orders",
    name: "orders",
    content: "订单页",
    component: ProductOrder,
  },
  {
    path: "/register",
    name: "register",
    content: "登录页面",
    component: Register,
  },
  {
    path: "/index",
    name: "index",
    content: "首页",
    redirect: '/index/studio',
    component: Index,
    children: [...homePages],
  },
  ...optionPages,
  ...music_theory_pages,
  ...MusicTheoryExaminationPages,
];

const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes,
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  beforEach(to, from, next);
});

export default router;
